<template>
  <div id="main">
    <div id="nav">
      <div class="nav-list">
        <div class="left">
          <img :src="headImg" />
          <p class="title">{{ title }}</p>
        </div>
        <a-menu v-model="current" mode="horizontal" :theme="theme">
          <a-menu-item v-for="item in Data" :key="item.key">
            <!-- <router-link
              v-if="item.target"
              tag="a"
              target="_blank"
              :to="item.url"
            >
              {{ item.title }}
            </router-link> -->
            <router-link :to="item.url">
              <!-- <a-icon :type="item.type" /> -->
              {{ item.title }}
            </router-link>
          </a-menu-item>
        </a-menu>

        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-avatar :size="48" icon="user" />
          </a>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item key="1" style="cursor: default">{{
              loginZh
            }}</a-menu-item>
            <a-menu-item key="2"> 修改密码 </a-menu-item>
            <a-menu-item key="4" v-if="isShowPlatform"> 管理后台 </a-menu-item>
            <a-menu-item key="3"> 退出 </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div class="body">
      <router-view />
    </div>
    <div class="foot" :class="footTop">
      <!-- <button @click="judgeAudio2">按钮</button> -->
      <audio id="mainAudio" controls="controls" ref="au" style="display: none">
        Your browser does not support the audio element.
      </audio>
      ©2020河南星火源科技有限公司<a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        >[豫ICP备18016332号]</a
      >
    </div>

    <!-- 侧边栏 -->
    <a-drawer
      :drawerStyle="drawerStyle"
      :bodyStyle="bodyStyle"
      class="drawer-shell"
      width="375px"
      :placement="placement"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <div class="alarm-list">
        <div style="flex: 1">
          <div class="alarm-one" v-for="(item, index) in HJJCData" :key="index">
            <div class="header">
              <img
                v-if="item.BJLX == 1"
                src="../assets/img/pics/020.png"
                alt=""
              />
              <img
                v-else-if="item.BJLX == 2"
                src="../assets/img/pics/021.png"
                alt=""
              />
              <img v-else src="../assets/img/pics/022.png" alt="" />
              <p class="title">
                {{ item.EnterpriseMC }}-{{ item.station_name }}
              </p>
              <a-icon
                size="32"
                @click="onDeleteJournalism(index)"
                type="close"
              />
            </div>
            <p class="text">{{ item.AlarmMsg }}</p>
            <p class="date">报警时间：{{ item.BJSJ }}</p>
          </div>
        </div>
        <!-- 底部的docked -->
        <a-affix v-if="HJJCData.length > 0" :offset-bottom="0" class="docked">
          <a-button
            type="primary"
            @click="
              () => {
                this.HJJCData = [];
              }
            "
          >
            清空信息
          </a-button>
        </a-affix>
        <!-- <div @click="showDrawerHide" class="alarm-button alarm-button-position">报<br />警<br />信<br />息<br />{{HJJCLength}}</div> -->
      </div>
    </a-drawer>
    <!-- style="position: fixed; left: 0; top: 5.4rem" -->
    <a-affix
      v-if="affixDrawer"
      :offset-top="menuBtnTop"
      :style="{ position: 'fixed', left: 0, top: menuBtnTop }"
    >
      <div @click="showDrawer" class="alarm-button">
        报<br />警<br />信<br />息<br />{{ HJJCLength }}
      </div>
    </a-affix>

    <!-- 修改密码的弹窗 -->
    <a-modal
      title="修改密码"
      :visible="visiblePwd"
      :confirm-loading="confirmLoading"
      @ok="changePWDOk"
      @cancel="changePWDCancel"
    >
      <div>
        <a-input-password
          style="height: 32px; margin: 10px auto"
          size="large"
          placeholder="请输入旧密码"
          :visibilityToggle="false"
          v-model="oPwd"
        />
        <a-input-password
          style="height: 32px; margin: 10px auto"
          size="large"
          placeholder="请输入新密码"
          :visibilityToggle="false"
          v-model="nPwd"
        />
        <a-input-password
          style="height: 32px; margin: 10px auto"
          size="large"
          placeholder="请再次输入新密码"
          :visibilityToggle="false"
          v-model="aPwd"
        />
      </div>
    </a-modal>
  </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
//base64 md5
import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
// CryptoJS key
const key = "qusukeji";
import { http } from "../utils/Http.js";

import { getHttp } from "../utils/GetHttp.js";

export default {
  name: "Main",
  data() {
    return {
      current: null,
      title: '',
      loginZh: "",
      theme: "dark",
      headImg: require("../assets/img/pics/logo.png"),
      Data: [
        {
          key: "home",
          url: "/main/home",
          type: "mail",
          title: "首页",
        },
        {
          key: "realTime",
          url: "/main/real",
          type: "appstore",
          title: "实时数据",
        },
        {
          key: "call",
          url: "/main/alarm/0",
          type: "appstore",
          title: "报警信息",
        },
        {
          key: "reportForm",
          url: "/main/report",
          type: "appstore",
          title: "报表查询",
        },
        {
          key: "history",
          url: "/main/historical",
          type: "appstore",
          title: "历史数据",
        },
        {
          key: "exceed",
          url: "/main/exceed",
          type: "appstore",
          title: "超标查询",
        },
        {
          key: "basics",
          url: "/main/basics",
          type: "appstore",
          title: "基础信息",
        },
        {
          key: "map",
          url: "map",
          type: "appstore",
          title: "地图",
          target: true,
        },
        {
          key: "screen",
          url: "/full",
          type: "appstore",
          title: "大屏",
        },
      ],

      //
      visible: false,
      placement: "left",
      HJJCData: [],
      HJJCLength: "",
      drawerStyle: {
        backgroundColor: "rgba(174, 205, 251,0.5)",
      },
      bodyStyle: {
        paddingTop: "24px",
        paddingBottom: "24px",
        paddingLeft: "17px",
        paddingRight: "17px",
        backgroundColor: "rgba(174, 205, 251,0.5)",
      },

      // 左侧弹窗相关
      menuBtnTop: 120,

      //修改密码相关
      visiblePwd: false,
      confirmLoading: false,
      nPwd: "", // 新密码
      oPwd: "", // 老密码
      aPwd: "", // 再次输入新密码

      //底部样式
      footTop: "foot-margin-top",

      //报警信息是否显示
      affixDrawer: true,
      //是否显示管理后台
      isShowPlatform:false
    };
  },

  watch: {
    current(newVal, oldVal) {},

    $route(to, from) {
      console.log(to);
      this.$store.commit("setTreeLX", from.meta.TreeLX); //展开指定的树节点
      this.current = [`${to.meta.key}`];

      if (to.name == "Map") {
        this.footTop = "";
        this.affixDrawer = false;
      } else {
        this.footTop = "foot-margin-top";
        this.affixDrawer = true;
      }
    },
  },

  created() {
    var me = this;
    if (this.$route.name == "Map") {
      this.footTop = "";
      this.affixDrawer = false;
    } else {
      this.footTop = "foot-margin-top";
      this.affixDrawer = true;
    }
    me.loginZh = JSON.parse(localStorage.getItem("loggedIn")).Phone;
    // 左侧菜单按钮的位置。
    me.menuBtnTop = document.body.offsetHeight / 2 - 80;

    var key = this.$route.meta.key;
    this.current = [`${key}`];

    if (localStorage.getItem("loggedIn")) {
      var loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      if(loggedIn.ROLEBH=='Role001'||loggedIn.ROLEBH=='Role002'||loggedIn.ROLEBH=='Role003'||loggedIn.ROLEBH=='Role005'){
        this.isShowPlatform=true;//控制显示管理后台
      }
      this.$store.commit("setLoggedIn", loggedIn);
    } else {
      this.$router.push("/login");
    }

    //端企业图标企业名称获取
    this.HJJC_EnterpriseIconName_Get();

    //左侧树结构调用接口
    // getHttp({
    // 	url: '/data/q30/HJJC_TreeListGet.aspx',
    // 	data: {
    // 		ckm: me.$store.state.loggedIn.CKM,
    // 		MC: '',
    // 		LX: ''
    // 	},
    // 	success(obj) {
    // 		// // // console.log(obj);
    // 		me.$store.commit('setTreeOriginalData', obj.Data);
    // 		let objTree = JSON.parse(JSON.stringify(obj));
    // 		if (objTree.Data && objTree.Data.length) {
    // 			for (var i = 0; i < objTree.Data.length; i++) {
    // 				if (obj.Data[i].leaf) {
    // 					// // // console.log(objTree.Data[i]);
    // 					me.$store.commit('setExpandedKeys', [objTree.Data[i].parentId]); //展开指定的树节点
    // 					me.$store.commit('setSelectedKeys', [objTree.Data[i].id]); //设置选中的树节点
    // 					me.$store.commit('setSelectedData', objTree.Data[i]); //选中的树节点数据对象

    // 					break
    // 				}

    // 				// objTree.Data[i].title = objTree.Data[i].name;
    // 				// objTree.Data[i].key = objTree.Data[i].id;
    // 				// objTree.Data[i].slots = {
    // 				// 	icon: 'folder',
    // 				// };
    // 			}

    // 		}

    // 		let parentData = objTree.Data.filter(it => it.id == me.$store.state.selectedData.parentId)
    // 		if (parentData && parentData.length > 0) {
    // 			me.$store.commit('setParentData', parentData[0]); //选中的树节点数据对象
    // 		}

    // 		// // // // console.log(me.toTreeData(objTree.Data));
    // 		var Data = me.toTreeData(objTree.Data);
    // 		me.$store.commit('setTreeData', Data);
    // 	},
    // 	failure(obj) {

    // 	},
    // 	me
    // });

    this.HJJC_XXTS_PC();
  },

  methods: {
    //payAudio//
    /**
     * 判断audio哪个进行语音播报
     */
    judgeAudio2(obj) {
      let me = this;
      let audio = me.$refs.au;
      console.log(audio);
      me.$nextTick(() => {
        audio.src = require("../assets/audio/1.mp3");
        console.log(audio);
        audio.play();
      });
      // mainAudio.src = "resources/1.mp3";
      // mainAudio.play();
      // mainAudio.onended = function () {
      //     this.src = 'resources/2.mp3';
      //     this.play();
      //     this.onended = null;
      // }
    },
    judgeAudio(obj) {
      let me = this;
      if (obj.Data && obj.Data.length > 0) {
        var ZT1 = 0;
        var ZT2 = 0;
        var ZT3 = 0;
        for (var i = 0; i < obj.Data.length; i++) {
          if (obj.Data[i].BJLX == 1) {
            ZT1++;
          } else if (obj.Data[i].BJLX == 2) {
            ZT2++;
          } else if (obj.Data[i].BJLX == 2) {
            ZT3++;
          }
        }
        // var mainAudio = document.getElementById('mainAudio');
        let audio = me.$refs.au;
        if (ZT1 > 0 && ZT2 == 0 && ZT3 == 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/1.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/1.mp3";
          // mainAudio.play();
        } else if (ZT1 == 0 && ZT2 > 0 && ZT3 == 0) {
          // mainAudio.src = "resources/2.mp3";
          // mainAudio.play();
          me.$nextTick(() => {
            audio.src = require("../assets/audio/2.mp3");
            audio.play();
          });
        } else if (ZT1 == 0 && ZT2 == 0 && ZT3 > 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/3.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/3.mp3";
          // mainAudio.play();
        } else if (ZT1 > 0 && ZT2 > 0 && ZT3 == 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/1.mp3");
            audio.play();
          });
          me.$nextTick(() => {
            audio.src = require("../assets/audio/2.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/1.mp3";
          // mainAudio.play();
          // mainAudio.onended = function () {
          // 	this.src = 'resources/2.mp3';
          // 	this.play();
          // 	this.onended = null;
          // }
        } else if (ZT1 > 0 && ZT2 == 0 && ZT3 > 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/1.mp3");
            audio.play();
          });
          me.$nextTick(() => {
            audio.src = require("../assets/audio/3.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/1.mp3";
          // mainAudio.play();
          // mainAudio.onended = function () {
          // 	this.src = 'resources/3.mp3';
          // 	this.play();
          // 	this.onended = null;
          // }
        } else if (ZT1 == 0 && ZT2 > 0 && ZT3 > 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/2.mp3");
            audio.play();
          });
          me.$nextTick(() => {
            audio.src = require("../assets/audio/3.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/2.mp3";
          // mainAudio.play();
          // mainAudio.onended = function () {
          // 	this.src = 'resources/3.mp3';
          // 	this.play();
          // 	this.onended = null;
          // }
        } else if (ZT1 > 0 && ZT2 > 0 && ZT3 > 0) {
          me.$nextTick(() => {
            audio.src = require("../assets/audio/1.mp3");
            audio.play();
          });
          me.$nextTick(() => {
            audio.src = require("../assets/audio/2.mp3");
            audio.play();
          });
          me.$nextTick(() => {
            audio.src = require("../assets/audio/3.mp3");
            audio.play();
          });
          // mainAudio.src = "resources/1.mp3";
          // mainAudio.play();
          // mainAudio.onended = function () {
          // 	this.src = 'resources/2.mp3';
          // 	this.play();
          // 	this.onended = function () {
          // 		this.src = 'resources/3.mp3';
          // 		this.play();
          // 		this.onended = null;
          // 	}
          // }
        }
      }
    },

    /**
     * 人物头像的点击事件
     */
    onClick({ key }) {
      // console.log(key);
      let me = this;
      if (key == 1) {
        return;
      }
      //修改密码
      if (key == 2) {
        me.oPwd = "";
        me.nPwd = "";
        me.aPwd = "";
        me.confirmLoading = false;
        me.visiblePwd = true;
      }
      //退出
      if (key == 3) {
        localStorage.removeItem("loggedIn", null);
        if(this.$cookie.get("loggedIned")){
          // this.$cookie.remove('loggedIned', { domain: '.warpsoft.cn' });
          this.$cookie.remove('loggedIned',{ domain: '.warpsoft.cn' });
        }
        // // // // console.log(localStorage.getItem('loggedIn'));
        this.$router.push("/login");
      }
      if(key==4){
        this.onEnterManage();
      }
    },

    //格式化树数据
    toTreeData(data) {
      var pos = {};
      var tree = [];
      var i = 0;
      while (data.length != 0) {
        if (data[i].parentId == "0") {
          tree.push({
            key: data[i].id,
            title: data[i].name,
            slots: {
              icon: "folder",
            },
            children: [],
          });
          pos[data[i].id] = [tree.length - 1];
          data.splice(i, 1);
          i--;
        } else {
          var posArr = pos[data[i].parentId];
          if (posArr != undefined) {
            var obj = tree[posArr[0]];
            for (var j = 1; j < posArr.length; j++) {
              obj = obj.children[posArr[j]];
            }

            obj.children.push({
              key: data[i].id,
              title: data[i].name,
              slots: {
                icon: "folder",
              },
              children: [],
            });
            pos[data[i].id] = posArr.concat([obj.children.length - 1]);
            data.splice(i, 1);
            i--;
          }
        }
        i++;
        if (i > data.length - 1) {
          i = 0;
        }
      }
      return tree;
    },

    /**
     * 侧边栏的显示
     */
    showDrawer() {
      this.visible = true;
    },

    /**
     * 侧边栏的隐藏
     */
    showDrawerHide() {
      this.visible = false;
    },

    onClose() {
      this.visible = false;
    },

    /**
     * 获取消息推送。
     * 每10分钟调用一次接口(600000 秒)
     */
    HJJC_XXTS_PC() {
      var me = this;

      http({
        url: "/data/HJJC_XXTS_PC.aspx",
        data: {
          CKM: this.$store.state.loggedIn.CKM,
        },
        success(obj) {
          console.log(obj);
          me.HJJCData = obj.Data;
          me.HJJCLength = obj.Data1[0].ITEM;

          me.judgeAudio(obj);

          setTimeout(() => {
            me.HJJC_XXTS_PC();
          }, 180000);
        },
        failure(obj) {
          setTimeout(() => {
            me.HJJC_XXTS_PC();
          }, 180000);
        },
        me,
      });
    },

    onDeleteJournalism(index) {
      // console.log(index);
      this.HJJCData.splice(index, 1);
    },

    /**
     * ---------     修改密码相关  ------------
     */

    /**
     * 修改密码 的确定按钮的点击事件
     */
    changePWDOk(e) {
      let me = this;
      let OLDPWD = me.oPwd,
        NEWPWD = me.nPwd,
        CONNEWPWD = me.aPwd;
      if (NEWPWD !== CONNEWPWD) {
        me.$message.error("两次填写密码不一致");
      } else {
        me.confirmLoading = true;
        http({
          url: "/data/q30/HJJC_CHANGEPWD.aspx",
          data: {
            CKM: this.$store.state.loggedIn.CKM,
            USERBH: JSON.parse(localStorage.getItem("loggedIn")).Phone,
            OLDPWD,
            NEWPWD,
            CONNEWPWD,
          },
          success(obj) {
            console.log(obj);
            me.visible = false;
            me.confirmLoading = false;

            me.$message.success("更改成功");
            localStorage.setItem("loggedIn", "");
            me.$router.push("/login");
          },
          failure(obj) {
            me.confirmLoading = false;
            me.$message.error(obj.errMsg);
          },
        });
      }
    },
    /**
     * 修改密码取消按钮的点击事件
     */
    changePWDCancel(e) {
      // console.log('Clicked cancel button');
      this.visiblePwd = false;
    },

    HJJC_EnterpriseIconName_Get() {
      let me = this;
      //   me.$store.commit('setCorpName', "企业");
      localStorage.setItem("corpName", "生态环境监测数据综合管理平台系统");

      http({
        url: "/data/q30/HJJC_EnterpriseIconName_Get.aspx",
        data: {
          CKM: this.$store.state.loggedIn.CKM,
        },
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            if (obj.Data[0].CorpName) {
              localStorage.setItem("corpName", obj.Data[0].CorpName);
            } else {
              localStorage.setItem(
                "corpName",
                "生态环境监测数据综合管理平台系统"
              );
            }

            if (obj.Data[0].Picture) {
              localStorage.setItem("headImg", `/data/ShowImage.ashx?guid=${obj.Data[0].Picture}`);
              me.headImg = `/data/ShowImage.ashx?guid=${obj.Data[0].Picture}`;
            } else {
              localStorage.setItem("headImg", '');
              me.headImg = require("../assets/img/pics/logo.png");
            }
          }
          me.title = localStorage.getItem("corpName");
        },
        failure(obj) {
          me.$message.info(obj.errMsg);
        },
        me,
      });
    },
    //进入管理端
    onEnterManage(){
      let loggedIn=JSON.parse(localStorage.getItem("loggedIn"));
      console.log(loggedIn);
      // let loggedInfo=localStorage.getItem("loggedInfo");
      // console.log(loggedInfo);
      //  // Decrypt 解密
      // let bytes = CryptoJS.AES.decrypt(loggedInfo, key);
      // let originalText = bytes.toString(CryptoJS.enc.Utf8);
      // // base64解密
      // //let pwd = Base64.decode(originalText);
      // let pwd = loggedInfo;
      // window.open("http://192.168.0.213:81/resources/redirect.html?uid="+loggedIn.Phone+'&name='+pwd+'&backurl=http://192.168.0.213:8080/');
      this.setToken(loggedIn);
      window.open("http://gl.allspark.top/resources/redirect.html?backurl=http://pc.allspark.top/");
    },
    //设置指定域名下的cookie
    setToken:function(loggedIn){
      //return this.$cookie.set("loggedIned", JSON.stringify(loggedIn));
      return this.$cookie.set("loggedIned", JSON.stringify(loggedIn), { domain: '.allspark.top'});
    }
  },
};
</script>


<style lang="scss">
#main {
  display: flex;
  flex-direction: column;
  height: 100%;

  #nav {
    background-color: #1876ff;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 100000;

    .nav-list {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      .left {
        display: flex;
        align-items: center;
        margin-right: 60px;

        img {
          height: 45px;
          margin-right: 10px;
        }

        .title {
          font-size: 20px;
          color: #ffffff;
        }
      }

      .ant-menu {
        flex: 1;
      }

      .ant-menu-horizontal {
        border: 0;
      }

      .ant-menu-dark {
        background: #1876ff;
      }

      .ant-menu-item {
        height: 60px;
        line-height: 60px;
        padding: 0;
        margin: 0 10px;
      }

      .ant-menu-item-selected {
        background: #1876ff;
        border-bottom: 4px solid #ffffff;
      }

      .ant-menu-item-active:hover {
        background: #1876ff;
        border-bottom: 4px solid #ffffff;
      }

      .ant-dropdown-link {
        height: 48px;
        width: 48px;
        margin-top: 6px;
        margin-left: 30px;
      }
    }
  }

  .body {
    flex: 1;
    margin-top: 60px;
  }

  .foot {
    padding-top: 12px;
    padding-bottom: 12px;
    // line-height: 70px;
    background: #181818;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
  }

  .foot-margin-top {
    margin-top: 1rem;
  }
}

.ant-drawer-body {
  // padding: 24px 16px;
  height: 100%;
  background-color: #aecdfb;

  //抽屉样式
  .alarm-list {
    height: 100%;
    position: relative;
    padding-top: 60px;
    display: flex;
    flex-direction: column;

    .alarm-one {
      height: 135px;
      width: 336px;
      background: url("../assets/img/pics/019.png") no-repeat center center;
      background-size: 100% 100%;
      margin-bottom: 20px;

      .header {
        height: 40px;
        line-height: 40px;
        position: relative;
        padding-bottom: 3px;
        margin-bottom: 12px;

        img {
          width: 48px;
          height: 40px;
          position: absolute;
          left: 0;
          top: 0;
        }

        .title {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding: 0 0 0 46px;
          font-weight: 700;
          padding-right: 20px;
          height: 40px;
          // text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .anticon {
          position: absolute;
          right: 5px;
          top: 5px;
          cursor: pointer;
        }
      }

      .text {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #181818;
        line-height: 17px;
        padding: 0 12px 9px;
        margin-bottom: 10px;
      }

      .date {
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        padding: 0 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #515151;
      }
    }
  }
}

//图钉样式
.alarm-button {
  width: 40px;
  height: 160px;
  background: url(../assets/img/pics/023.png);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
  text-align: center;
  padding-top: 23px;
  cursor: pointer;
}
.docked {
  background-color: rgb(174, 205, 251);
  padding: 10px 0px;
  text-align: center;
  height: 60px;
  .ant-affix {
    background-color: rgb(174, 205, 251);
    padding: 10px 0px;
    text-align: center;
    height: 60px;
  }
}
</style>



<style>
.ant-drawer-wrapper-body {
  position: relative;
}

.alarm-button-position {
  position: absolute;
  right: -17px;
  top: 4.1rem;
}

.ant-drawer-wrapper-body::-webkit-scrollbar {
  width: 6px;
  background-color: #78afff;
}

.ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
  width: 6px;
  height: 20px;
  border-radius: 3px;
  background-color: #fff;
}
.ant-drawer-content {
  background: rgba(255, 255, 255, 0.4) !important;
}
</style>

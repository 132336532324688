import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/AppMain.vue'


import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


Vue.use(VueRouter)

const routes = [{
    path: '*',
    component: () =>
        import('../views/AppLogin.vue')
}, {
    path: '/',
    redirect: 'login'
}, {
    path: '/backstage',
    name:'backstage',
    component: () =>
    import('../views/backstage.vue')
}, {
    path: '/login',
    name: 'Login',
    component: () =>
        import('../views/AppLogin.vue'),
    meta: {
        title: "登录",
    }
},
{ //apk下载页面
    path: '/apkdownload',
    name: 'apkdownload',
    component: () =>
        import('../views/apkdownload.vue'),
    meta: {
        title: "apk下载页面"
    }
},
{
    path: '/main',
    name: 'Main',
    redirect: '/main/home',
    component: () =>
        import('../views/AppMain.vue'),
    children: [{ //首页
        path: 'home',
        name: 'Home',
        component: () =>
            import('../views/folder/AppHome.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5 //页面左侧如果有树结构 公司为叶子节点值为 5  基站为叶子节点值为 6
        }
    },
    { //首页-日分析报告
        path: 'analysis',
        name: 'Analysis',
        component: () =>
            import('../views/folder/AnalysisReport.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //首页-基站数据异常列表
        path: 'abnormal',
        name: 'Abnormal',
        component: () =>
            import('../views/folder/AbnormalBase.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //首页-基站数据异常列表
        path: 'transmission',
        name: 'Transmission',
        component: () =>
            import('../views/folder/TransmissionRate.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //首页-总量查询
        path: 'total',
        name: 'Total',
        component: () =>
            import('../views/folder/TotalQuery.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //首页-停运基站列表
        path: 'stop',
        name: 'Stop',
        component: () =>
            import('../views/folder/StopBase.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //首页-添加基站	
        path: 'addbase/:TYID',
        name: 'Addbase',
        component: () =>
            import('../views/folder/AddBaseForm.vue'),
        meta: {
            key: 'home',
            title: "首页",
            TreeLX: 5
        }
    },
    { //实时数据
        path: 'real',
        name: 'Real',
        component: () =>
            import('../views/folder/RealTime.vue'),
        meta: {
            key: 'realTime',
            title: "实时数据",
            TreeLX: 5
        }
    },
    { //报警信息
        path: 'alarm/:TYPE',
        name: 'Alarm',
        component: () =>
            import('../views/folder/AlarmInformation.vue'),
        meta: {
            key: 'call',
            title: "报警信息",
            TreeLX: 6
        }
    },
    { //报表查询
        path: 'report',
        name: 'Report',
        component: () =>
            import('../views/folder/ReportForm.vue'),
        meta: {
            key: 'reportForm',
            title: "报表查询",
            TreeLX: 6
        }
    },
    { //历史数据
        path: 'historical',
        name: 'Historical',
        component: () =>
            import('../views/folder/HistoricalData.vue'),
        meta: {
            key: 'history',
            title: "历史数据",
            TreeLX: 6
        }
    },
    { //超标查询
        path: 'exceed',
        name: 'Exceed',
        component: () =>
            import('../views/folder/AppExceed.vue'),
        meta: {
            key: 'exceed',
            title: "超标查询",
            TreeLX: 5
        }
    },
    { //基础信息
        path: 'basics',
        name: 'Basics',
        component: () =>
            import('../views/folder/AppBasics.vue'),
        meta: {
            key: 'basics',
            title: "基础信息",
            TreeLX: 6
        }
    },
    { //地图-实验
        path: 'yanzheng',
        name: 'Yanzheng',
        component: () =>
            import('../views/folder/MapYanzheng.vue'),
        meta: {
            key: 'map',
            title: "地图验证",
            TreeLX: 6
        }
    },
    {
        path: 'map',
        name: 'Map',
        component: () =>
            import('../views/map/AppMap.vue'),
        meta: {
            key: 'map',
            title: "地图",
            TreeLX: 6
        }
    },
    ]
},
// {
// 	path: '/map',
// 	name: 'Map',
// 	component: () => import('../views/map/AppMap.vue'),
// 	meta: {
// 		key: 'map',
// 		title: "地图",
// 		TreeLX: 6
// 	}
// },
{
    path: '/full',
    name: 'Full',
    component: () =>
        import('../views/Full/FullScreen.vue'),
    meta: {
        key: 'screen',
        title: "大屏"
    }
},
{ //首页-日分析报告-导出操作页面
    path: '/export',
    name: 'Export',
    component: () =>
        import('../views/folder/AnalysisReportExport.vue'),
    meta: {
        title: "首页",
    }
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 进度条相关：网站：https://madewith.cn/23
NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
    let me = this;


    document.title = localStorage.getItem("corpName") || "生态环境监测数据综合管理平台系统";



    // if(to.name == "Login"){
    // 	document.title = "生态环境监测数据综合管理平台系统" + to.meta.title;
    // }else{
    // 	document.title = "重点污染源监控数据管理平台" + to.meta.title;
    // }

    NProgress.start();
    next();
});

router.afterEach(transition => {
    NProgress.done();
});

export default router
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
})
import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie;
import axios from 'axios'
Vue.prototype.$axios = axios
    // axios.defaults.baseURL = '' //关键代码
   //axios.defaults.baseURL = '/api' //关键代码
   //axios.defaults.baseURL='http://pc.allspark.top'
   //axios.defaults.baseURL = 'http://www.warpsoft.cn:184' //关键代码

//百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'R51ZHE6rbUYGmEaGmyG03AcTgAeUFwB7'
})


//安装echarts
import * as echarts from 'echarts'
// 【注释】：import echarts from 'echarts' 引入echarts后，不能全局使用echarts,
// 所以通过Vue.prototype 将echarts保存为全局变量。原则上$echarts可以为任意变量名。
Vue.prototype.$echarts = echarts

import { message } from 'ant-design-vue';
Vue.prototype.$message = message;

Vue.config.productionTip = false

Vue.use(Antd);
axios.get('./serverConfig.json').then((res) => {
    const myconfig = {
      baseURL: res.data.baseURL
    };
    axios.defaults.baseURL = myconfig.baseURL; //关键代码
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
    Vue.prototype.$myconfig = myconfig;
})
// new Vue({
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')
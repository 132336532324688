<template>
  <a-config-provider :locale="locale">
    <div id="app" v-cloak>
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      locale: zhCN,
    };
  },

  created() {
    var me = this;

    // 改变窗口大小时重新设置 rem
    window.onresize = function () {
      me.setRem();
    };

    // 进入时就重新设置
    window.onload = function () {
      me.setRem();
    };
  },

  methods: {
    setRem() {
      const baseSize = 100;
      // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 1920;
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + "px";
    },
  },
};
</script>


<style lang="scss">
[v-cloak] {
  display: none;
}

html {
  height: 100%;

  body {
    height: 100%;
    background-color: #f2f2f2;

    #app {
      background-color: rgb(242, 242, 242);
      font-family: "Microsoft YaHei";
      font-size: 14px;
      height: 100%;
      // font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #181818;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>

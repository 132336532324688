import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from "dayjs";
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

Vue.use(Vuex)


//一个月前的数据
var myDate = new Date();
// var JSSJ = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
var JSSJ = dayjs(new Date()).format("YYYY-MM-DD");
myDate.setMonth(myDate.getMonth() - 1);
// var KSSJ = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
var KSSJ = dayjs(new Date()).add(-1, 'month').format("YYYY-MM-DD");

var KSSJ7 = dayjs().add(-7, "day").format("YYYY-MM-DD");
var KSSJ1 = dayjs().add(-1, "day").format("YYYY-MM-DD");
console.log(KSSJ);
console.log(JSSJ);
//昨天
var yesterday = dayjs().add(-1, "day").format("YYYY-MM-DD");

//本季度第一天
var thisSeason = dayjs().startOf('quarter').format("YYYY-MM-DD");

//本月第一天
var thisMonth = dayjs().startOf('month').format("YYYY-MM-DD");


// 获取网站
let HTTPURL = `${window.location.protocol}//${window.location.host}`;

export default new Vuex.Store({
    state: {
        loggedIn: {},
        treeOriginalData: null, //树原始数据
        treeData: null, //树数据
        expandedKeys: null, //选中的树节点父级-(展开指定的树节点)
        selectedKeys: null, //设置选中的树节点    
        selectedData: {}, //选中的树节点数据对象   
        parentData: {}, //选中的树节点父级数据对象  
        TreeLX: null, //树节点叶子类型

        KSSJ7,
        KSSJ1,
        KSSJ: KSSJ, //一个月前
        JSSJ: JSSJ, //今天
        yesterday: yesterday, //昨天
        thisSeason: thisSeason, //本季度第一天
        thisMonth: thisMonth, //本月度第一天

        //停运基站开始结束时间
        TYJZKSSJ: yesterday,
        TYJZJSSJ: JSSJ,

        baseData: null, //树节点的数据类型

        corpName: "生态环境监测数据综合管理平台系统", //企业名称


        HTTPURL: HTTPURL, //当前网站的网址


        JZMC: '',
    },
    mutations: {
        //登录
        setLoggedIn(state, value) {
            state.loggedIn = value;
        },

        //树数据
        setTreeData(state, value) {
            state.treeData = value;
        },

        //树原始数据
        setTreeOriginalData(state, value) {
            state.treeOriginalData = value;
        },

        //展开指定的树节点
        setExpandedKeys(state, value) {
            state.expandedKeys = value;
        },

        //设置选中的树节点
        setSelectedKeys(state, value) {
            state.selectedKeys = value;
        },

        //选中的树节点数据对象
        setSelectedData(state, value) {
            state.selectedData = value;
        },

        //选中的树节点父级数据对象
        setParentData(state, value) {
            state.parentData = value;
        },

        //树节点的数据类型
        setBaseData(state, value) {
            state.baseData = value;
        },

        setTreeLX(state, value) {
            state.TreeLX = value;
        },


        setCorpName(state, value) {
            state.corpName = value;
        },


        setJZMC(state, value) {
            state.JZMC = value;
        },

        setTYJZKSSJ(state, value) {
            state.TYJZKSSJ = value;
        },

        setTYJZJSSJ(state, vlaue) {
            state.TYJZJSSJ = vlaue;
        },

    },
    actions: {},
    modules: {}
})
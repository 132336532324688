const axios = require('axios');

export function http(options) {
    var data = options.data;
    // console.log(data);
    var params = new URLSearchParams();
    for (let key in data) {
        params.append(key, data[key]);
    }
    return axios.post(options.url, params).then(res => {
        var obj = res.data;
        // console.log(obj);
        if (obj.result == 0) {
            if (typeof options.success === 'function') {
                options.success(obj);
            }
        } else {
            // options.me.$message.info(obj.errMsg);
            options.failure(obj);
            if (obj.errMsg == '用户验证码已过期或不存在' || obj.errMsg == '验证码无效') {
                options.me.$router.push("/login");
            } else {
                options.me.$error({
                    title: "提示",
                    centered: true,
                    content: obj.errMsg,
                });
            }
        }
    }).catch(err => {

    })
}
export function http2(options) {
    var data = options.data;
    // console.log(data);
    var params = new URLSearchParams();
    for (let key in data) {
        params.append(key, data[key]);
    }
    return axios.post(options.url, params).then(res => {
        var obj = res.data;
        // console.log(obj);
        if (obj.result == 0) {
            if (typeof options.success === 'function') {
                options.success(obj);
            }
        } else {
            //options.me.$message.info(obj.errMsg);

            options.failure(obj);
            if (obj.errMsg == '用户验证码已过期或不存在' || obj.errMsg == '验证码无效') {
                options.me.$router.push("/login");
            } else {
                options.me.$error({
                    title: "提示",
                    centered: true,
                    content: obj.errMsg,
                });
            }
        }
    }).catch(err => {

    })
}
export function httpGet(options) {
    var data = options.data;
    // console.log(data);
    var params = new URLSearchParams();
    for (let key in data) {
        params.append(key, data[key]);
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
    return axios.get(options.url, params).then(res => {
        var obj = res.data;
        if (res.status == 200) {
            if (typeof options.success === 'function') {
                options.success(obj);
            }
        } else {
            options.failure(obj);
        }
    }).catch(err => {

    })
}
export default {
    http,
    http2,
    httpGet
}